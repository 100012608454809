<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ 'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = shallShowActiveChatContactSidebar = shallShowUserProfileSidebar = false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!chatConversation.length"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          Yozishmani boshlash
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.profile_picture_url"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                badge-variant="success"
                @click.native="shallShowActiveChatContactSidebar = true"
              />
              <h6 class="mb-0">
                {{ activeChat.username }}
              </h6>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center">
              <div class="dropdown">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    View Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Mute Notifications
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Block Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Clear Chat
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Report
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :chat-conversation="chatConversation"
          />
        </vue-perfect-scrollbar>

        <!-- Scroll Bottom Button -->
        <button
          class="scroll-bottom-btn"
          @click="scrollToBottomInChatLog"
        >
          Scroll Down
        </button>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Xabar yozing"
            />
          </b-input-group>
          <b-overlay :show="loadingBtn">
            <b-button
              variant="primary"
              type="submit"
            >
              Yuborish
            </b-button>
          </b-overlay>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <!-- <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat.contact || {}"
    /> -->

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :conversations-list="conversationsList"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfConversation"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { showToast } from '@/utils/toast'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatLog from './ChatLog.vue'

export default {
  components: {
    VuePerfectScrollbar,
    ChatLeftSidebar,
    ChatLog,
  },
  data() {
    return {
      // conversationsList: [],
      // chatConversation: [],
      activeChat: {},
      chatInputMessage: '',
      chatsContacts: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      loadingBtn: false,
      profileUserData: {},
      profileUserDataMinimal: {},
      shallShowUserProfileSidebar: false,
      shallShowActiveChatContactSidebar: false,
      mqShallShowLeftSidebar: false,
    }
  },
  computed: {
    conversationsList() {
      return store.getters['chat/GET_ITEMS']
    },
    chatConversation() {
      return store.getters['chat/GET_ITEM']
    },
  },
  created() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)
  },
  mounted() {
    this.getConversations()
  },
  beforeDestroy() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
  },
  methods: {
    async getConversations() {
      await store.dispatch('chat/getInstagramConversations', {})
      // .then(res => {
      //   this.conversationsList = res.data
      // })
    },
    scrollToBottomInChatLog() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    openChatOfConversation(userData) {
      this.chatInputMessage = ''
      this.changeQuery('chat_id', userData.user_id)
      store.dispatch('chat/getInstagramConversationById', { id: userData.user_id })
        .then(() => {
          // this.chatConversation = response.data
          this.activeChat = userData
          this.$nextTick(() => { this.scrollToBottomInChatLog() })
        })
      this.mqShallShowLeftSidebar = false
    },

    sendMessage() {
      if (!this.chatInputMessage) return
      const payload = {
        recipient_id: this.activeChat.user_id,
        message: this.chatInputMessage,
      }
      this.loadingBtn = true
      store.dispatch('chat/sendIgMessage', payload)
        .then(response => {
          if (response && response.message_id) {
            this.openChatOfConversation(this.activeChat)
          } else {
            showToast('danger', this.$t('Ошибка'), 'XIcon')
          }
          this.chatInputMessage = ''
          this.$nextTick(() => { this.scrollToBottomInChatLog() })
        }).finally(() => {
          this.loadingBtn = false
        })
    },
    showUserProfileSidebar() {
      store.dispatch('app-chat/getProfileUser')
        .then(response => {
          this.profileUserData = response.data
          this.shallShowUserProfileSidebar = true
        })
    },
    startConversation() {
      this.getConversations()
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        this.mqShallShowLeftSidebar = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .scroll-bottom-btn {
    position: fixed;
    right: 20px;
    bottom: 80px;
    padding: 10px 15px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .scroll-bottom-btn:hover {
    background: #0056b3;
  }
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
